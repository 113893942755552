/*
|----------------------------------------------
| PRINT
|----------------------------------------------
| print stylings
|
*/


$page-print-margin: 1.5cm;

@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
  }

  body {
    font-size: 76%;
  }

  a {
    &:visited {
      text-decoration: underline;
    }
  }

  //a[href]:after                   {content: ' [' attr(href) ']'; }
  //abbr[title]:after               {content: ' [' attr(title) ']'; }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: $page-print-margin;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3,
  h4 {
    page-break-after: avoid;
  }
}
