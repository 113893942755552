/*
|----------------------------------------------
| MODULE
|----------------------------------------------
|
| sample template
|
*/


.module-sample {

  width: 100%;

  // children without classes can be nested inside the module:

  li {
    width: 100%;
  }

  // definitions based on the layout should be avoided, where we need to make an exception we write like this:
  // and not in the _header.scss !!


  .header & {
    width: 50%;
  }
}

// child elements with classname should be placed outside of the parent scope and named after their parent:

.module-sample-child {
  display: inline;
}
