/*
|----------------------------------------------
| BASE
|----------------------------------------------
| base styles for html elements
|
*/

.logo {
  float: left;
  min-width: 189px;

  @include breakpoint(xlarge down) {
    width: 300px;
  }

  @include breakpoint(large down) {
    width: 250px;
  }

  @media (max-width: 1120px) {
    width: 189px;
  }

  @include breakpoint(medium down) {
    width: 220px;
  }

  @include breakpoint(small down) {
    width: 189px;
  }
}

.logo-white {
  @include breakpoint(large down) {
    width: 220px;
  }

  @include breakpoint(small down) {
    width: 189px;
  }
}