/*
|----------------------------------------------
| BASE
|----------------------------------------------
| base styles for html elements
|
*/

$tap-highlight-color: transparent;

html,
body {
  height: 100%;
}

html {
  text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: $tap-highlight-color;
}

// remove outline on focus

a,
button,
input {
  &:focus {
    outline: none;
  }
}

// SELECTION

::selection {
  background: $primary-color;
  color: #fff;
  text-shadow: none;
}

body {
  width: 100%;
  height: 100%;
  color: #0d0a0a;
  font-size: 16px;
  position: relative;

  &::after {
    position: fixed;
    background: url('../images/bg.svg') no-repeat right -255px top 15px;
    background-size: 1800px;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    content: "";
    z-index: -1;

    @include breakpoint(large up) {
      top: 30px;
    }
  }

  @include breakpoint(medium down) {
    &::after {
      background-size: 1200px;
      background-position: right -190px top 35px;
    }
  }

  @include breakpoint(small down) {
    &::after {
      background-size: 800px;
      background-position: right -111px top 75px;
    }
  }
}

p {
  font-size: 16px;
}

.wrapper {
  min-height: 100%;
  padding-bottom: 182px;
  margin-bottom: -82px;

  @include breakpoint(small down) {
    padding-bottom: 132px;
  }
}

.subpage {
  display: inline-block;
  width: 100%;
  margin-top: 90px;

  @include breakpoint(small down) {
    margin-top: 50px;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    line-height: 30px;

    @include breakpoint(small down) {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.lock {
  overflow: hidden;
}

.item {
  display: inline-block;
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px #000 solid;
  position: relative;

  p {
    margin-bottom: 0;

    a {
      color: #df5479;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @include breakpoint(medium down) {
    &.no-top {
      padding-top: 0;
    }
  }

  img {
    max-width: 320px;
    float: left;
    margin-right: 30px;
    margin-bottom: 10px;

    @include breakpoint(medium down) {
      max-width: 150px;
    }

    @media (max-width: 360px) {
      max-width: 100%;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .plus {
    position: absolute;
    right: 0;
    bottom: 30px;
    width: 21px;
    height: 21px;
    background: url('../images/plus.svg') no-repeat;

    &.shown {
      background: url('../images/minus.svg') no-repeat;
    }
  }

  &.pd-item {
    padding: 10px 30px 10px 0;

    h2 {
      margin: 8px 0 0;
    }

    p {
      margin: 10px 0;
    }

    .plus {
      bottom: 23px;
    }
  }
}

.more {
  display: none;

  &.display {
    display: inline !important;
  }
}

.pp-item {
  display: inline-block;
  width: 100%;
  padding: 15px 40px 10px 0;
  border-bottom: 1px #000 solid;
  position: relative;

  h2 {
    margin: 0;
    padding: 0;
    line-height: 20px;
  }

  p {
    margin-bottom: 0;
  }

  .download {
    position: absolute;
    right: 0;
    top: 50%;
    width: 32px;
    height: 23px;
    background: url('../images/more.svg') no-repeat;
    margin-top: -11px;
  }
}

.mb {
  @include breakpoint(medium down) {
    display: none;
  }
}

.mbr {
  display: none;

  @media (max-width: 580px) {
    display: inline-block;
  }
}


.cookie-overlay {
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  z-index: 115;
  top: 0;
  left: 0;
  width: 100%;

  .cookie-inner {
    padding: 10px;

    p {
      display: inline-block;
      font-size: 12px;
    }

    button {
      display: inline-block;
      padding: 10px;
      background: #df5579;
      border-radius: 5px;
      color: #fff;
      margin: 0 0 10px 10px;
      font-size: 13px;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;

      &:hover {
        cursor: pointer;
        background: #ee94a5;
      }
    }
  }
}
/*
|----------------------------------------------
| HEADINGS
|----------------------------------------------
|
*/

h1 {
  margin: 0 0 65px;
  font-size: 64px;
  letter-spacing: 4px;
  font-weight: 300;

  &.uppercase {
    text-transform: uppercase;
  }

  @include breakpoint(large down) {
    font-size: 54px;
    line-height: 64px;
    margin-bottom: 55px;
  }

  @include breakpoint(medium down) {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 35px;
  }

  @include breakpoint(small down) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}

h2 {
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 28px;
  text-transform: uppercase;
  margin: 0 0 15px;

  @include breakpoint(medium down) {
    font-size: 16px;
    line-height: 24px;
  }
}
/*
|----------------------------------------------
| HELPERS
|----------------------------------------------
| things like no-margin paragraphs,..
| all the helpers are prefixed with underscore
|
*/

._unmargin {
  margin: 0 !important;
}

._inline-list {
  li {
    display: inline;
  }
}

._no-bullet {
  li {
    padding: 0;

    &::before {
      display: none;
    }
  }
}
