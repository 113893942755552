@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&subset=latin-ext");
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
  display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
  display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=96.25em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #F1C7B4;
  font-family: "Quicksand", Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #0d0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
}

[data-whatinput='mouse'] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 76.25rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}

.row .row {
  margin-right: -0.9375rem;
  margin-left: -0.9375rem;
}

@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}

.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}

.row.expanded {
  max-width: none;
}

.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}

.row:not(.expanded) .row {
  max-width: none;
}

.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.row.is-collapse-child,
.row.collapse > .column > .row,
.row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  min-width: 0;
}

@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}

.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}

.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}

.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}

.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}

.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}

.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}

.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}

.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 40em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 40em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}

@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px;
  }
}

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}

@media print, screen and (min-width: 64em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 64em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.875rem;
}

.column-block > :last-child {
  margin-bottom: 0;
}

@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Quicksand", Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility;
}

h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: #cacaca;
}

h1, .h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3, .h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4, .h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3rem;
  }
  h2, .h2 {
    font-size: 2.5rem;
  }
  h3, .h3 {
    font-size: 1.9375rem;
  }
  h4, .h4 {
    font-size: 1.5625rem;
  }
  h5, .h5 {
    font-size: 1.25rem;
  }
  h6, .h6 {
    font-size: 1rem;
  }
}

a {
  line-height: inherit;
  color: #0d0a0a;
  text-decoration: none;
  cursor: pointer;
}

a:hover, a:focus {
  color: #0b0909;
}

a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 76.25rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}

dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca;
}

blockquote, blockquote p {
  line-height: 1.6;
  color: #8a8a8a;
}

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a;
}

cite:before {
  content: "— ";
}

abbr, abbr[title] {
  border-bottom: 1px dotted #0d0a0a;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #0d0a0a;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0d0a0a;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #8a8a8a;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}

p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .print-break-inside {
    page-break-inside: auto;
  }
}

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #0d0a0a;
  color: #fff;
}

[data-whatinput='mouse'] .button {
  outline: 0;
}

.button:hover, .button:focus {
  background-color: #0b0909;
  color: #fff;
}

.button.tiny {
  font-size: 0.6rem;
}

.button.small {
  font-size: 0.75rem;
}

.button.large {
  font-size: 1.25rem;
}

.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

.button.primary {
  background-color: #1779ba;
  color: #fff;
}

.button.primary:hover, .button.primary:focus {
  background-color: #126195;
  color: #fff;
}

.button.secondary {
  background-color: #767676;
  color: #000;
}

.button.secondary:hover, .button.secondary:focus {
  background-color: #5e5e5e;
  color: #000;
}

.button.success {
  background-color: #3adb76;
  color: #000;
}

.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #000;
}

.button.warning {
  background-color: #ffae00;
  color: #000;
}

.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #000;
}

.button.alert {
  background-color: #cc4b37;
  color: #000;
}

.button.alert:hover, .button.alert:focus {
  background-color: #a53b2a;
  color: #000;
}

.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  background-color: #0d0a0a;
  color: #fff;
}

.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #1779ba;
  color: #fff;
}

.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #767676;
  color: #000;
}

.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #000;
}

.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #000;
}

.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}

.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #000;
}

.button.hollow {
  border: 1px solid #0d0a0a;
  color: #0d0a0a;
}

.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}

.button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: transparent;
}

.button.hollow:hover, .button.hollow:focus {
  border-color: #070505;
  color: #070505;
}

.button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
  border: 1px solid #0d0a0a;
  color: #0d0a0a;
}

.button.hollow.primary {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}

.button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.hollow.secondary {
  border: 1px solid #767676;
  color: #767676;
}

.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b;
}

.button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
  border: 1px solid #767676;
  color: #767676;
}

.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a;
}

.button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear {
  border: 1px solid #0d0a0a;
  color: #0d0a0a;
}

.button.clear, .button.clear:hover, .button.clear:focus {
  background-color: transparent;
}

.button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: transparent;
}

.button.clear:hover, .button.clear:focus {
  border-color: #070505;
  color: #070505;
}

.button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border: 1px solid #0d0a0a;
  color: #0d0a0a;
}

.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border-color: transparent;
}

.button.clear.primary {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.clear.primary:hover, .button.clear.primary:focus {
  border-color: #0c3d5d;
  color: #0c3d5d;
}

.button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border: 1px solid #1779ba;
  color: #1779ba;
}

.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border-color: transparent;
}

.button.clear.secondary {
  border: 1px solid #767676;
  color: #767676;
}

.button.clear.secondary:hover, .button.clear.secondary:focus {
  border-color: #3b3b3b;
  color: #3b3b3b;
}

.button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border: 1px solid #767676;
  color: #767676;
}

.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border-color: transparent;
}

.button.clear.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.clear.success:hover, .button.clear.success:focus {
  border-color: #157539;
  color: #157539;
}

.button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}

.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border-color: transparent;
}

.button.clear.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning:hover, .button.clear.warning:focus {
  border-color: #805700;
  color: #805700;
}

.button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}

.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border-color: transparent;
}

.button.clear.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear.alert:hover, .button.clear.alert:focus {
  border-color: #67251a;
  color: #67251a;
}

.button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}

.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border-color: transparent;
}

.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fefefe transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}

.button.dropdown.hollow::after {
  border-top-color: #0d0a0a;
}

.button.dropdown.hollow.primary::after {
  border-top-color: #1779ba;
}

.button.dropdown.hollow.secondary::after {
  border-top-color: #767676;
}

.button.dropdown.hollow.success::after {
  border-top-color: #3adb76;
}

.button.dropdown.hollow.warning::after {
  border-top-color: #ffae00;
}

.button.dropdown.hollow.alert::after {
  border-top-color: #cc4b37;
}

.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none;
}

.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.75rem;
  line-height: 1;
  color: foreground(#fefefe, #0d0a0a);
}

:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0;
}

.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}

.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: '+';
}

.is-active > .accordion-title::before {
  content: '\2013';
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: foreground(#fefefe, #0d0a0a);
}

:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

/*
|----------------------------------------------
| Font embed
|----------------------------------------------
|
|  Usage:
|
|  .woff2 with .woff fallback
|
|  Converters:
|
|  http://www.fontsquirrel.com/tools/webfont-generator
|  http://everythingfonts.com/ttf-to-woff2
|
|  ** Note about Ubuntu, google version OSX issues with certain charactes, use our fixed local version.
|
*/
/*
|----------------------------------------------
| MIXINS
|----------------------------------------------
| - circle mixin
| - triangle mixin
|
*/
/*
|----------------------------------------------
| VARIABLES
|----------------------------------------------
| global variables
|
|
*/
/*
|----------------------------------------------
| COLORS
|----------------------------------------------
| color definitions
|
|
*/
/* Fonts */
/*
|----------------------------------------------
| BASE
|----------------------------------------------
| base styles for html elements
|
*/
html,
body {
  height: 100%;
}

html {
  text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

a:focus,
button:focus,
input:focus {
  outline: none;
}

::selection {
  background: #1779ba;
  color: #fff;
  text-shadow: none;
}

body {
  width: 100%;
  height: 100%;
  color: #0d0a0a;
  font-size: 16px;
  position: relative;
}

body::after {
  position: fixed;
  background: url("../images/bg.svg") no-repeat right -255px top 15px;
  background-size: 1800px;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  content: "";
  z-index: -1;
}

@media screen and (min-width: 64em) {
  body::after {
    top: 30px;
  }
}

@media screen and (max-width: 63.9375em) {
  body::after {
    background-size: 1200px;
    background-position: right -190px top 35px;
  }
}

@media screen and (max-width: 39.9375em) {
  body::after {
    background-size: 800px;
    background-position: right -111px top 75px;
  }
}

p {
  font-size: 16px;
}

.wrapper {
  min-height: 100%;
  padding-bottom: 182px;
  margin-bottom: -82px;
}

@media screen and (max-width: 39.9375em) {
  .wrapper {
    padding-bottom: 132px;
  }
}

.subpage {
  display: inline-block;
  width: 100%;
  margin-top: 90px;
}

@media screen and (max-width: 39.9375em) {
  .subpage {
    margin-top: 50px;
  }
}

.subpage h1 {
  margin-bottom: 20px;
}

.subpage p {
  line-height: 30px;
}

@media screen and (max-width: 39.9375em) {
  .subpage p {
    font-size: 14px;
    line-height: 24px;
  }
}

.lock {
  overflow: hidden;
}

.item {
  display: inline-block;
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px #000 solid;
  position: relative;
}

.item p {
  margin-bottom: 0;
}

.item p a {
  color: #df5479;
  text-decoration: none;
}

.item p a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 63.9375em) {
  .item.no-top {
    padding-top: 0;
  }
}

.item img {
  max-width: 320px;
  float: left;
  margin-right: 30px;
  margin-bottom: 10px;
}

@media screen and (max-width: 63.9375em) {
  .item img {
    max-width: 150px;
  }
}

@media (max-width: 360px) {
  .item img {
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
  }
}

.item .plus {
  position: absolute;
  right: 0;
  bottom: 30px;
  width: 21px;
  height: 21px;
  background: url("../images/plus.svg") no-repeat;
}

.item .plus.shown {
  background: url("../images/minus.svg") no-repeat;
}

.item.pd-item {
  padding: 10px 30px 10px 0;
}

.item.pd-item h2 {
  margin: 8px 0 0;
}

.item.pd-item p {
  margin: 10px 0;
}

.item.pd-item .plus {
  bottom: 23px;
}

.more {
  display: none;
}

.more.display {
  display: inline !important;
}

.pp-item {
  display: inline-block;
  width: 100%;
  padding: 15px 40px 10px 0;
  border-bottom: 1px #000 solid;
  position: relative;
}

.pp-item h2 {
  margin: 0;
  padding: 0;
  line-height: 20px;
}

.pp-item p {
  margin-bottom: 0;
}

.pp-item .download {
  position: absolute;
  right: 0;
  top: 50%;
  width: 32px;
  height: 23px;
  background: url("../images/more.svg") no-repeat;
  margin-top: -11px;
}

@media screen and (max-width: 63.9375em) {
  .mb {
    display: none;
  }
}

.mbr {
  display: none;
}

@media (max-width: 580px) {
  .mbr {
    display: inline-block;
  }
}

.cookie-overlay {
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  z-index: 115;
  top: 0;
  left: 0;
  width: 100%;
}

.cookie-overlay .cookie-inner {
  padding: 10px;
}

.cookie-overlay .cookie-inner p {
  display: inline-block;
  font-size: 12px;
}

.cookie-overlay .cookie-inner button {
  display: inline-block;
  padding: 10px;
  background: #df5579;
  border-radius: 5px;
  color: #fff;
  margin: 0 0 10px 10px;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
}

.cookie-overlay .cookie-inner button:hover {
  cursor: pointer;
  background: #ee94a5;
}

/*
|----------------------------------------------
| HEADINGS
|----------------------------------------------
|
*/
h1 {
  margin: 0 0 65px;
  font-size: 64px;
  letter-spacing: 4px;
  font-weight: 300;
}

h1.uppercase {
  text-transform: uppercase;
}

@media screen and (max-width: 74.9375em) {
  h1 {
    font-size: 54px;
    line-height: 64px;
    margin-bottom: 55px;
  }
}

@media screen and (max-width: 63.9375em) {
  h1 {
    font-size: 42px;
    line-height: 52px;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 39.9375em) {
  h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 20px;
  }
}

h2 {
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 28px;
  text-transform: uppercase;
  margin: 0 0 15px;
}

@media screen and (max-width: 63.9375em) {
  h2 {
    font-size: 16px;
    line-height: 24px;
  }
}

/*
|----------------------------------------------
| HELPERS
|----------------------------------------------
| things like no-margin paragraphs,..
| all the helpers are prefixed with underscore
|
*/
._unmargin {
  margin: 0 !important;
}

._inline-list li {
  display: inline;
}

._no-bullet li {
  padding: 0;
}

._no-bullet li::before {
  display: none;
}

/*
|----------------------------------------------
| BASE
|----------------------------------------------
| base styles for html elements
|
*/
.logo {
  float: left;
  min-width: 189px;
}

@media screen and (max-width: 96.1875em) {
  .logo {
    width: 300px;
  }
}

@media screen and (max-width: 74.9375em) {
  .logo {
    width: 250px;
  }
}

@media (max-width: 1120px) {
  .logo {
    width: 189px;
  }
}

@media screen and (max-width: 63.9375em) {
  .logo {
    width: 220px;
  }
}

@media screen and (max-width: 39.9375em) {
  .logo {
    width: 189px;
  }
}

@media screen and (max-width: 74.9375em) {
  .logo-white {
    width: 220px;
  }
}

@media screen and (max-width: 39.9375em) {
  .logo-white {
    width: 189px;
  }
}

/*
|----------------------------------------------
| MODULE
|----------------------------------------------
|
| sample template
|
*/
.module-sample {
  width: 100%;
}

.module-sample li {
  width: 100%;
}

.header .module-sample {
  width: 50%;
}

.module-sample-child {
  display: inline;
}

/*
|----------------------------------------------
| FOOTER
|----------------------------------------------
|
| Footer Stylings for container only, do not write components/module properties here like footer navigation...
|
*/
.footer {
  background: #0d0a0a;
  height: 82px;
  color: #fefefe;
  font-size: 14px;
  text-align: left;
}

@media screen and (max-width: 63.9375em) {
  .footer {
    height: auto;
    min-height: 82px;
    padding: 15px 0;
    text-align: center;
    align-items: center;
    display: flex;
  }
  .footer .text-right {
    text-align: center;
  }
}

.footer .row {
  align-items: center;
  height: 100%;
}

.footer a {
  color: #fefefe;
}

.footer a:hover, .footer a:focus {
  text-decoration: underline;
  color: #fefefe;
}

.footer p {
  font-size: 14px;
  margin: 0;
  font-weight: 300;
}

@media screen and (max-width: 63.9375em) {
  .footer p {
    font-size: 13px;
  }
}

@media screen and (max-width: 39.9375em) {
  .footer p {
    font-size: 11px;
  }
}

.footer p span {
  margin: 0 12px;
}

@media screen and (max-width: 39.9375em) {
  .footer p span {
    margin: 0 5px;
  }
}

/*
|----------------------------------------------
| HEADER
|----------------------------------------------
|
| Header Stylings
|
*/
.header {
  display: inline-block;
  width: 100%;
  margin-top: 70px;
  padding: 0 100px;
}

@media (max-width: 1590px) {
  .header {
    padding: 0 60px;
  }
}

@media screen and (max-width: 74.9375em) {
  .header {
    padding: 0 45px;
    margin-top: 30px;
  }
}

@media screen and (max-width: 63.9375em) {
  .header {
    padding: 0 15px;
    margin-top: 30px;
  }
}

.top-bar {
  float: right;
  padding: 0;
  margin: 14px 0 0;
}

@media screen and (max-width: 96.1875em) {
  .top-bar {
    margin: 0;
  }
}

.top-bar li {
  list-style: none;
  float: left;
  margin-left: 100px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}

@media screen and (max-width: 96.1875em) {
  .top-bar li {
    margin-left: 50px;
  }
}

@media (max-width: 1260px) {
  .top-bar li {
    margin-left: 40px;
  }
}

@media (max-width: 1120px) {
  .top-bar li {
    margin-left: 30px;
  }
}

@media screen and (max-width: 63.9375em) {
  .top-bar li {
    margin: 0;
    width: 100%;
    text-align: center;
  }
}

.top-bar li a:focus, .top-bar li a:hover {
  color: #df5479;
}

@media screen and (max-width: 63.9375em) {
  .top-bar li a:focus, .top-bar li a:hover {
    color: #fefefe;
  }
}

@media screen and (max-width: 63.9375em) {
  .top-bar li a {
    margin: 15px 0;
    color: #fefefe;
    width: 100%;
    display: inline-block;
    font-weight: 300;
  }
}

.top-bar li.active a {
  font-weight: bold;
}

.top-bar .ul-nav {
  float: left;
  margin-left: 0;
}

@media screen and (max-width: 63.9375em) {
  .top-bar .ul-nav {
    background: url("../images/line.png") no-repeat center bottom;
  }
}

.top-bar .ul-nav li:first-child {
  margin-left: 0;
  display: none;
}

.top-bar .ul-nav li:nth-child(2) {
  margin-left: 0;
}

.top-bar .nav-header {
  display: none;
  width: 100%;
  margin-bottom: 20px;
  padding: 30px 15px 15px;
}

.top-bar .close {
  margin: 5px 5px 0 0;
  display: inline-block;
}

.top-bar .nav {
  display: inline-block;
}

@media screen and (min-width: 40em) {
  .top-bar .nav {
    border-bottom: 1px #0d0a0a solid;
    margin-bottom: 13px;
  }
}

@media screen and (max-width: 63.9375em) {
  .top-bar .nav {
    text-align: center;
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #df5479;
    left: 0;
    top: 0;
    z-index: 10;
  }
  .top-bar .nav.opened {
    display: block;
  }
  .top-bar .nav.opened .nav-header {
    display: inline-block;
  }
  .top-bar .nav ul {
    display: inline-block;
    width: 100%;
    padding-bottom: 30px;
    margin: 0;
  }
}

.hamburger {
  display: none;
  width: 24px;
  height: 19px;
  margin-top: 10px;
  background: url("../images/hamburger.svg") no-repeat;
}

@media screen and (max-width: 63.9375em) {
  .hamburger {
    display: block;
    float: right;
  }
}

.lang {
  float: right;
  margin-left: 90px;
  margin-left: 60px;
}

@media screen and (max-width: 96.1875em) {
  .lang {
    margin-left: 50px;
  }
}

@media screen and (max-width: 74.9375em) {
  .lang {
    margin-left: 40px;
  }
}

@media screen and (max-width: 63.9375em) {
  .lang {
    float: none;
    display: inline-block;
    margin: 40px 0 0;
  }
}

.lang ul {
  padding: 0;
  margin: 0;
}

.lang ul li {
  margin: 0 10px;
  position: relative;
}

@media screen and (max-width: 63.9375em) {
  .lang ul li {
    display: inline-block;
    width: auto;
  }
}

.lang ul li::after {
  position: absolute;
  left: -14px;
  top: 0;
  content: "/";
  color: #df5479;
}

@media screen and (max-width: 63.9375em) {
  .lang ul li::after {
    color: #fefefe;
  }
}

.lang ul li:first-child::after {
  display: none;
}

@media screen and (min-width: 64em) {
  .lang ul li:last-child {
    margin-right: 0;
  }
}

.lang ul li a {
  color: #df5479;
}

@media screen and (max-width: 63.9375em) {
  .lang ul li a {
    color: #fefefe;
    margin: 0;
  }
}

/*
|----------------------------------------------
| HOMEPAGE
|----------------------------------------------
| 
|
|
*/
.box {
  margin-top: 190px;
  text-align: center;
}

@media screen and (max-width: 96.1875em) {
  .box {
    margin-top: 170px;
  }
}

@media screen and (max-width: 74.9375em) {
  .box {
    margin-top: 130px;
  }
}

@media screen and (max-width: 63.9375em) {
  .box {
    margin-top: 100px;
  }
}

@media screen and (max-width: 39.9375em) {
  .box {
    margin-top: 50px;
  }
}

.box p {
  display: inline-block;
  max-width: 810px;
  font-size: 20px;
  line-height: 40px;
}

@media screen and (max-width: 39.9375em) {
  .box p {
    font-size: 14px;
    line-height: 26px;
  }
}

/*
|----------------------------------------------
| PRINT
|----------------------------------------------
| print stylings
|
*/
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
  }
  body {
    font-size: 76%;
  }
  a:visited {
    text-decoration: underline;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 1.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3,
  h4 {
    page-break-after: avoid;
  }
}
