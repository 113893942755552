/*
|----------------------------------------------
| FOOTER
|----------------------------------------------
|
| Footer Stylings for container only, do not write components/module properties here like footer navigation...
|
*/
.footer {
  background: #0d0a0a;
  height: 82px;
  color: $white;
  font-size: 14px;
  text-align: left;

  @include breakpoint(medium down) {
    height: auto;
    min-height: 82px;
    padding: 15px 0;
    text-align: center;
    align-items: center;
    display: flex;

    .text-right {
      text-align: center;
    }
  }

  .row {
    align-items: center;
    height: 100%;
  }

  a {
    color: $white;

    &:hover,
    &:focus {
      text-decoration: underline;
      color: $white;
    }
  }

  p {
    font-size: 14px;
    margin: 0;
    font-weight: 300;

    @include breakpoint(medium down) {
      font-size: 13px;
    }

    @include breakpoint(small down) {
      font-size: 11px;
    }

    span {
      margin: 0 12px;

      @include breakpoint(small down) {
        margin: 0 5px;
      }
    }
  }
}
/*
|----------------------------------------------
| HEADER
|----------------------------------------------
|
| Header Stylings
|
*/
.header {
  display: inline-block;
  width: 100%;
  margin-top: 70px;
  padding: 0 100px;

  @media (max-width: 1590px) {
    padding: 0 60px;
  }

  @include breakpoint(large down) {
    padding: 0 45px;
    margin-top: 30px;
  }

  @include breakpoint(medium down) {
    padding: 0 15px;
    margin-top: 30px;
  }
}

.top-bar {
  float: right;
  padding: 0;
  margin: 14px 0 0;

  @include breakpoint(xlarge down) {
    margin: 0;
  }

  li {
    list-style: none;
    float: left;
    margin-left: 100px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;

    @include breakpoint(xlarge down) {
      margin-left: 50px;
    }

    @media (max-width: 1260px) {
      margin-left: 40px;
    }

    @media (max-width: 1120px) {
      margin-left: 30px;
    }

    @include breakpoint(medium down) {
      margin: 0;
      width: 100%;
      text-align: center;
    }

    a {
      &:focus,
      &:hover {
        color: #df5479;

        @include breakpoint(medium down) {
          color: $white;
        }
      }

      @include breakpoint(medium down) {
        margin: 15px 0;
        color: $white;
        width: 100%;
        display: inline-block;
        font-weight: 300;
      }
    }

    &.active {
      a {
        font-weight: bold;
      }
    }
  }

  .ul-nav {
    float: left;
    margin-left: 0;

    @include breakpoint(medium down) {
      background: url('../images/line.png') no-repeat center bottom;
    }

    li {
      &:first-child {
        margin-left: 0;
        display: none;
      }

      &:nth-child(2) {
        margin-left: 0;
      }
    }
  }

  .nav-header {
    display: none;
    width: 100%;
    margin-bottom: 20px;
    padding: 30px 15px 15px;
  }

  .close {
    margin: 5px 5px 0 0;
    display: inline-block;
  }

  .nav {
    display: inline-block;

    @include breakpoint(medium up) {
      border-bottom: 1px #0d0a0a solid;
      margin-bottom: 13px;
    }

    @include breakpoint(medium down) {
      text-align: center;
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(223, 84, 121, 1);
      left: 0;
      top: 0;
      z-index: 10;

      &.opened {
        display: block;

        .nav-header {
          display: inline-block;
        }
      }

      ul {
        display: inline-block;
        width: 100%;
        padding-bottom: 30px;
        margin: 0;
      }
    }
  }
}

.hamburger {
  display: none;
  width: 24px;
  height: 19px;
  margin-top: 10px;
  background: url('../images/hamburger.svg') no-repeat;

  @include breakpoint(medium down) {
    display: block;
    float: right;
  }
}

.lang {
  float: right;
  margin-left: 90px;

  @include breakpoint(xxlarge down) {
    margin-left: 60px;
  }

  @include breakpoint(xlarge down) {
    margin-left: 50px;
  }

  @include breakpoint(large down) {
    margin-left: 40px;
  }

  @include breakpoint(medium down) {
    float: none;
    display: inline-block;
    margin: 40px 0 0;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      margin: 0 10px;
      position: relative;

      @include breakpoint(medium down) {
        display: inline-block;
        width: auto;
      }

      &::after {
        position: absolute;
        left: -14px;
        top: 0;
        content: "/";
        color: #df5479;

        @include breakpoint(medium down) {
          color: $white;
        }
      }

      &:first-child {
        &::after {
          display: none;
        }
      }

      &:last-child {
        @include breakpoint(large up) {
          margin-right: 0;
        }
      }

      a {
        color: #df5479;

        @include breakpoint(medium down) {
          color: $white;
          margin: 0;
        }
      }
    }
  }
}