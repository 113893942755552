/*
|----------------------------------------------
| HOMEPAGE
|----------------------------------------------
| 
|
|
*/
.box {
  margin-top: 190px;
  text-align: center;

  @include breakpoint(xlarge down) {
    margin-top: 170px;
  }

  @include breakpoint(large down) {
    margin-top: 130px;
  }

  @include breakpoint(medium down) {
    margin-top: 100px;
  }

  @include breakpoint(small down) {
    margin-top: 50px;
  }

  p {
    display: inline-block;
    max-width: 810px;
    font-size: 20px;
    line-height: 40px;

    @include breakpoint(small down) {
      font-size: 14px;
      line-height: 26px;
    }
  }
}