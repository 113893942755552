/*
|----------------------------------------------
| Font embed
|----------------------------------------------
|
|  Usage:
|
|  .woff2 with .woff fallback
|
|  Converters:
|
|  http://www.fontsquirrel.com/tools/webfont-generator
|  http://everythingfonts.com/ttf-to-woff2
|
|  ** Note about Ubuntu, google version OSX issues with certain charactes, use our fixed local version.
|
*/

@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&subset=latin-ext');
/*
|----------------------------------------------
| MIXINS
|----------------------------------------------
| - circle mixin
| - triangle mixin
|
*/

@mixin circle($size, $bg-color, $color) {
  background: $bg-color;
  color: $color;
  display: inline-block;
  width: $size;
  height: $size;
}

@mixin triangle($size, $color, $direction) {

  height: 0;
  width: 0;

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {

    border-color: transparent;
    border-style: solid;
    border-width: $size / 2;

    @if $direction == up {
      border-bottom-color: $color;
    }

    @else if $direction == right {
      border-left-color: $color;
    }

    @else if $direction == down {
      border-top-color: $color;
    }

    @else if $direction == left {
      border-right-color: $color;
    }
  }

  @else if ($direction == up-right) or ($direction == up-left) {

    border-top: $size solid $color;

    @if $direction == up-right {
      border-left: $size solid transparent;
    }

    @else if $direction == up-left {
      border-right: $size solid transparent;
    }
  }

  @else if ($direction == down-right) or ($direction == down-left) {

    border-bottom: $size solid $color;

    @if $direction == down-right {
      border-left: $size solid transparent;
    }

    @else if $direction == down-left {
      border-right: $size solid transparent;
    }
  }
}

/*
|----------------------------------------------
| VARIABLES
|----------------------------------------------
| global variables
|
|
*/


// Paths

$path-img: '/img/';
$path-img-bg: '/img/bg';
$path-img-ico: '/img/ico';

/*
|----------------------------------------------
| COLORS
|----------------------------------------------
| color definitions
|
|
*/

$red: #FE0032;
$light-grey: #efefef;
$grey: #c9c9c9;
$dark-grey: #333;

/* Fonts */

$QuickSand: 'Quicksand', sans-serif;